import React from "react"
import { Col, Jumbotron, Row } from "react-bootstrap"
import styled from 'styled-components'
import { ArrowRight } from '../components/Images'
import { PublicLayout } from '../components/layout'
import { Buttons, Seo } from '../components/navigation'
import PI from "../components/ProgressiveImages"
import { GrowRow } from "../components/utils"

const Section = styled(({ className = '', ...props }) => <Col xs={12} lg={6} className={`${className}`} {...props} />)``
const Header = styled(({ className = '', ...props }) => <h2 className={`${className} mb-3`} {...props} />)``
const Content = styled(({ className = '', ...props }) => <p className={`${className} text-125 mb-5`} {...props} />)``
const AvoidWrap = styled.b``//`display:inline-block;`

const IndexPage = () => {
  const whySubclubRef = React.useRef()

  return <PublicLayout linkRefs={{ intro: whySubclubRef }}>
    <Seo.Root />

    {/* Banner */}
    <Jumbotron className="m-0">
      <Row>
        <Section>
          <h2>SubClub: The best way to keep track of your subscriptions</h2>
          <p>Get insights into your hidden costs. Manage your subscriptions and get notified when bills are due.</p>
          <GrowRow>
            <Buttons.Login className="my-3">Get started for free</Buttons.Login>
          </GrowRow>
        </Section>
        <Section className="d-none d-md-flex justify-content-center align-items-start overflow-hidden">
          <PI.PhoneBlurBackground className="d-flex flex-grow-1 flex-shrink-0" />
        </Section>
      </Row>
    </Jumbotron>

    {/* Features */}
    <Jumbotron className="bg-white">
      <Row ref={whySubclubRef}>
        <Section>
          <Header>What is SubClub?</Header>
          {/* keep track of lock-in contracts
          remind you when your plans/services/reoccuring bills end */}
          {/* modify an existing contract
          remind */}
          <Content>SubClub is an online platform that helps you keep track of your recurring <AvoidWrap>bills, memberships, plans, subscriptions and contracts</AvoidWrap>.</Content>

          <Header>Why?</Header>
          <Content>Most people don&apos;t reassess their bills, and are stuck on antiquated deals that <AvoidWrap>cost more money</AvoidWrap> and <AvoidWrap>provide less service</AvoidWrap>.</Content>

          <Header>How?</Header>
          <Content>Receive notifications when your bills / memberships / plans / subscriptions / contracts are due to expire, so that you can <AvoidWrap>switch to a better plan</AvoidWrap>.</Content>
        </Section>

        <Section className="d-none d-md-flex justify-content-center align-items-start overflow-hidden">
          <PI.WomanWithPhone className="d-flex flex-grow-1 flex-shrink-0" />
        </Section>
      </Row>

      <GrowRow className="justify-content-center mt-5">
        <Buttons.Login>Get started for free <ArrowRight /></Buttons.Login>
      </GrowRow>
    </Jumbotron>

    {/* Testimonials */}
    <Jumbotron className="bg-white border-top">
      <h2 className="text-uppercase text-100 text-center text-muted">what our users are saying</h2>
      <div className="m-4 text-150 text-center">I'm paying $20 less each month, and I have 10x more mobile phone data!</div>
      <GrowRow className="justify-content-center">
        <div className="d-flex flex-row">
          <img className="rounded-circle" src="https://s.gravatar.com/avatar/241d9d59883aedf1dda43fa180e8809d?s=60" alt="user avatar" />
          <div className="text-left text-90 pl-3">
            <b>Nick Grealy</b>
            <div>Co-Founder and Director<br /> of Mountain Pass</div>
          </div>
        </div>
      </GrowRow>
    </Jumbotron>

    {/* Call To Action */}
    <Jumbotron className="bg-white border-top text-center">
      <h2>Ready to get started?</h2>
      <Buttons.Login className="my-3">Get started for free <ArrowRight /></Buttons.Login>
    </Jumbotron>

  </PublicLayout>
}

export default IndexPage
